'use strict';

(function($, window, document, undefined) {

	var APP = window.APP = window.APP || {};

	APP.util = {
		exec: function(controller, action) {
			var ns = APP,
				act = (action === undefined) ? 'init' : action;

			if (controller !== '' && ns[controller] && typeof ns[controller][act] === 'function') {
				ns[controller][act]();
			}
		},
		init: function() {

			APP.util.exec('common');
			if ( $('#jsApp').length ){
				var controller = $('#jsApp').attr('data-controller');
				console.log(controller);
				APP.util.exec(controller);
			}
		}
	}; 
	APP.common = (function() {

		var init = function() {
			console.log('common');
			anchorButton();
			mobileMenu();
		};

		var anchorButton = function() {
			$('.skip-banner').click(function(e){
				e.preventDefault();
				var el = $(this);
				$('html, body').animate({
			        scrollTop: $(el.attr('href')).offset().top
			    }, 600);
			})
		}

		var mobileMenu = function() {
			$('.mobile-menu-toggle').click(function(){
				$('body').toggleClass('mobile-on');
			})
		}

		return {
			init: init
		};

	})();

	APP.home = (function() {

		var init = function() {
			initiateSlick();
		};

		var initiateSlick = function() {
			$('.homepage-slider').slick({
				infinite: true,
				slide: '.single-slide',
				arrows: false,
				speed: 800,
				autoplay: true,
  				autoplaySpeed: 10000,
  				dots: true,
  				cssEase: 'cubic-bezier(1.000, -0.190, 0.375, 1.005)'
			});

			setTimeout(function(){
				$('.slick-active').addClass('slide-up');
			}, 300)

			$('.homepage-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
				$('.js-slideshow-button').removeClass('active');
				$('.js-slideshow-button:nth-child('+(currentSlide+1)+')').addClass('active');
				$('.single-slide').removeClass('slide-up');
				$('.slick-active').addClass('slide-up');
			})

			$('.js-slideshow-button').click(function(e){
				e.preventDefault();
				$('.js-slideshow-button').removeClass('active');
				$('.homepage-slider').slick('slickGoTo', ($(this).attr('data-slide'))-1, false);
			});

			$('.arrow-banner').click(function(e){
				$('.homepage-slider').slick('slickNext', false);
			});
		}

		return {
			init: init
		};

	})();

	APP.projects = (function() {

		var init = function() {
			initiateSlick();
		};

		var initiateSlick = function() {
			$('.slideshow-project').slick({
				infinite: true,
				slide: '.project-single-slide',
				speed: 800,
				slidesToShow: 4,
				responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 450,
					settings: {
						slidesToShow: 1
					}
				}]
			});

			$('.project-single-slide').click(function(e){
				e.preventDefault();
				updateModal( $(this).attr('data-image-order') );
			})
		}

		var updateModal = function(el) {
			$('.js-modal-picture').removeClass('image-visible');
			$('.js-modal-picture:nth-child('+el+')').addClass('image-visible');
		}

		return {
			init: init
		};

	})();


	APP.contact = (function() {

		var init = function() {
			initiateGoogleMap();
		};

		var initiateGoogleMap = function(){
			var myLatLng = {lat: 45.500, lng: -73.560};
			var theMarker = {lat: 45.500, lng: -73.560};

			// Specify features and elements to define styles.
			var styleArray = [
			{
			    'featureType': 'administrative',
			    'elementType': 'labels.text.fill',
			    'stylers': [
			        {
			            'color': '#444444'
			        }
			    ]
			},
			{
			    'featureType': 'landscape',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'color': '#f2f2f2'
			        }
			    ]
			},
			{
			    'featureType': 'poi',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'visibility': 'off'
			        }
			    ]
			},
			{
			    'featureType': 'road',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'saturation': -100
			        },
			        {
			            'lightness': 45
			        }
			    ]
			},
			{
			    'featureType': 'road.highway',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'visibility': 'simplified'
			        }
			    ]
			},
			{
			    'featureType': 'road.arterial',
			    'elementType': 'labels.icon',
			    'stylers': [
			        {
			            'visibility': 'off'
			        }
			    ]
			},
			{
			    'featureType': 'transit',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'visibility': 'off'
			        }
			    ]
			},
			{
			    'featureType': 'water',
			    'elementType': 'all',
			    'stylers': [
			        {
			            'color': '#276fed'
			        },
			        {
			            'visibility': 'on'
			        }
			    ]
			}];
 
		  // Create a map object and specify the DOM element for display.
		  var map = new google.maps.Map(document.getElementById('map'), {
		    center: myLatLng,
		    scrollwheel: false,
		    styles: styleArray,
		    zoom: 15
		  });

		  // Create a marker and set its position.
		  var marker1 = new google.maps.Marker({
		    map: map,
		    position: theMarker,
		    icon: "/wp-content/themes/Torrentiel/assets/dist/images/icon-map.png"
		  });	
		}

		return {
			init: init
		};

	})();

})(jQuery, window, document);

$(document).ready(APP.util.init);

function initMap() {
	if( $( '#APP[data-controller="contact"]' ).length ) {
		APP.contact.init();
	}
}